<template>
  <b-clockpicker 
    ref="timepicker"
    v-model="time"
    locale="de-DE"
    :hour-format="hourFormat"
    :time-formatter="timeFormatter.formatTime"
    :time-parser="timeFormatter.parseTime"
    editable
    v-on="fieldEvents"
  >
    <div class="is-flex is-justify-content-space-between">
      <b-button
        label="Jetzt"
        type="is-primary"
        icon-left="clock"
        class="pl-3 pr-3"
        @click="selectCurrentTime"
      />
      <b-button
        label="Löschen"
        type="is-danger"
        icon-left="close"
        outlined
        class="pl-3 pr-3"
        @click="time = null"
      />
      <b-button
        label="Ok"
        type="is-primary"
        icon-left="check"
        class="pl-3 pr-3"
        @click="timepicker.toggle()"
      />
    </div>
  </b-clockpicker>
</template>
  
<script >
// libs
import { ref, computed } from '@vue/composition-api';
import moment from 'moment-timezone';
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  },
  values: {
    type: Object,
    required: true
  },
  errors: {
    type: Object,
    required: true
  },
  fieldEvents: {
    type: Object,
    default: () => ({})
  },
  customEventHandler: {
    type: Function,
    default: () => {}
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const timepicker = ref(null);

  // composables specific to this component
  const useTimeFormatter = () => {
    const timeFormatter = {};

    /**
     * @param {Date} date 
     */
    timeFormatter.formatTime = date => {
      const userTimeZone = moment.tz.guess();
      const localTime = moment.tz(date, userTimeZone);
      return localTime.format(timePickerFormat.value);
    };

    /**
     * @param {string} dateString 
     */
    timeFormatter.parseTime = dateString => {
      const userTimeZone = moment.tz.guess();
      let momentObject = moment.tz(dateString, timePickerFormat.value, userTimeZone);
      return momentObject.toDate();
    };
    return timeFormatter;
  };

  // use composables
  const timeFormatter = useTimeFormatter();
  const time = computed({
    get() {
      const timeString = props.values[props.field.id];
      if (timeString) {
        return timeFormatter.parseTime(timeString);
      }
      return null;
    },
    set(value) {
      const formattedTime = timeFormatter.formatTime(value);
      emit('update', {
        value: formattedTime
      });
    }
  });
  const hourFormat = computed(() => {
    if (props.field.properties.basic.is24HourFormat) {
      return '24';
    } else {
      return '12';
    }
  });
  const timePickerFormat = computed(() => hourFormat.value === '12' ? 'hh:mm A' : 'HH:mm');
  const selectCurrentTime = () => {
    time.value = new Date();
    timepicker.value?.toggle();
  };
  return {
    timepicker,
    timeFormatter,
    time,
    hourFormat,
    selectCurrentTime
  };
};
export default __sfc_main;
</script>
